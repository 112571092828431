<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <!-- <v-row style="margin: 0%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-btn v-if="innerWidth > 1000" class="styleBtn2 mx-1" :outlined="(showInfo!=0)?true:false" color="#4880C8" :style="{'color' : (showInfo!=0) ? '#4880C8' : '#FFF'}"
        @click="showInfo=0">{{$t('__couponInfoBtn1')}}</v-btn>
        <v-btn v-if="innerWidth > 1000" class="styleBtn2 mx-1" :outlined="(showInfo!=1)?true:false" color="#4880C8" :style="{'color' : (showInfo!=1) ? '#4880C8' : '#FFF'}"
        @click="showInfo=1">{{$t('__couponInfoBtn2')}}</v-btn>
        <h4 v-if="innerWidth <= 1000" class="ma-2">{{$t('__functionMenu')}}</h4>
        <v-select v-if="innerWidth <= 1000" class="text_field" dense background-color="white" outlined style="max-width:50%"
        :items="btnList" :item-text="(language=='cn') ? 'cnName' : (language=='en') ? 'enName' : 'twName'" item-value="valuev" v-model="showInfo">
        </v-select>
      </v-row> -->
      
      <v-row v-if="showInfo==0" style="margin: 3%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '3%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '3%'}]">
        <v-col class="col-12 col-md-6 my-auto" align="center"> 
          <v-img :src="images.couponDetailImg" width=500 alt="action_04"></v-img>
        </v-col>
        <v-col class="col-12 col-md-6 px-0" align="center">
          <v-row class="my-2 px-5 text-left weight600">
            <div v-html="content" style="width:100%; max-height:660px; overflow-y:auto"></div>
          </v-row>
          <v-row class="my-2 px-5 text-left weight600">
            <v-btn class="styleBtn2 mx-1" color="#3F3D56" style="width:100%; color:#FFF"
            @click="showInfo=1">
            <v-icon color="#FFF">mdi-calendar-month</v-icon>
            <div class="pa-1">{{$t('__couponInfoBtn2')}}</div>
            </v-btn>
          </v-row>
          <v-row class="my-2 px-5 text-left weight600">
            <v-btn class="styleBtn2 mx-1" color="#4880C8" style="width:100%; color:#FFF"
            @click="redirectPath('/couponList')">
            <v-icon color="#FFF">mdi-gift</v-icon>
            <div class="pa-1">{{$t('__myCouponList')}}</div>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="showInfo==1" style="margin: 3%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-md-6" style="padding:2% 4%">
          <vc-calendar :attributes="attrs" style="width: 100%; border: none" @dayclick="updateCalendarDate"
          locale="en" :masks="{title: 'MMM YYYY', weekdays: 'WWW.'}">
          </vc-calendar>
          <v-row class="ma-3 pa-0" justify="center" align="center">
            <v-icon class="pa-1" color="#a0aec0" small>mdi-circle</v-icon>
            <h4 class="pr-1">{{$t('__calendarDateInfo1')}}</h4>
            <v-icon class="pa-1" color="#48bb78" small>mdi-circle</v-icon>
            <h4 class="pr-1">{{$t('__calendarDateInfo2')}}</h4>
            <v-icon class="pa-1" color="#3182ce" small>mdi-circle</v-icon>
            <h4 class="pr-1">{{$t('__calendarDateInfo3')}}</h4>
            <v-icon class="pa-1" color="#e53e3e" small>mdi-circle</v-icon>
            <h4>{{$t('__calendarDateInfo4')}}</h4>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-6 px-0" align="center">
          <div class="pa-5 text-left">
            <h1 class="styleH4" style="font-weight:800">{{memberName}} {{$t('__couponHello')}}</h1>
            <h1 class="styleH4" style="font-weight:800">{{$t('__couponHelloMsg1')}} <span class="start" style="font-size:38px"> {{dietDays}} </span> / 30 {{$t('__couponHelloMsg2')}}</h1>
            <v-row class="mx-0 my-4" style="justify-content:center">
              <div style="width:50%; min-width: 300px">
                <v-img :src="images.useDay" width=500 alt="useDay pic"></v-img>
              </div>
            </v-row>
            <v-row class="mx-0 my-2" style="justify-content:center">
              <div class="proportion" :style="{'background' : (proportion>10) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>20) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>30) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>40) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>50) ? '#4880C8' : '#B1B1B1'}"></div>

              <div class="proportion" :style="{'background' : (proportion>60) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>70) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>80) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>90) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportion" :style="{'background' : (proportion>100) ? '#4880C8' : '#B1B1B1'}"></div>
            </v-row>
            
            <v-row class="mt-8 mb-2 px-5 text-left weight600">
              <v-btn class="styleBtn2 mx-1" color="#3F3D56" style="width:100%; color:#FFF"
              @click="showInfo=0">
              <v-icon color="#FFF">mdi-bell-circle</v-icon>
              <div class="pa-1">{{$t('__couponInfoBtn1')}}</div>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
      <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
      @click="redirectPath('/couponPage')">
        <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
      </div>
      <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import moment from 'moment'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'couponInfo',
  components: {Loading},
  props: {},
  mounted(){
    this.language = i18n.locale
    this.memberId = sessionStorage.getItem('myCalId')
    this.memberName = sessionStorage.getItem('myCalName')
    this.getDataset()
    this.getMemberDietList()
    this.getDietDays()
  },
  data:() => ({
    language:'',
    memberName:'',
    memberId:'',
    showInfo:0,
    datasets:[],
    haveToken: false,
    pagination:{page:1,totalPage:10,totalCount:10},
    orderBy:'id',
    direction:'desc',
    destPage:'',
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
      couponDetailImg: require('@/assets/image/couponDetail.png'),
      marathon: require('@/assets/image/marathon.png'),
      useDay: require('@/assets/image/useDay.png'),
      couponImg: require('@/assets/image/couponImg.png'),
      recordImg: require('@/assets/image/recordImg.png'),
    },
    content:'',
    isLoading: false,
    
    memberDietList:[],
    attrs: [],
    dietDays:'',
    proportion: 0,
    displayMode:'',
    btnList:[
      {twName:'兌換機制',cnName:'兑换机制',enName:'Exchange mechanism',valuev:0},
      {twName:'使用狀態',cnName:'使用状态',enName:'Usage status',valuev:1},
    ],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
      if(window.innerWidth >= 960){
        this.displayMode = 0
      } else {
        this.displayMode = 1
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    getTypevFindIndex(list, value){
      var index = list.findIndex(function(item){return item.code == value})
      return (index != -1) ? list[index].name : null
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.datasets.twContent
      }else if(this.language=='cn'){
        this.content = this.datasets.cnContent
      }else if(this.language=='en'){
        this.content = this.datasets.enContent
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/CouponExchange',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    getDietDays(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/totalDietDays',
        params: {
          memberId: self.memberId,
        },
      }
      this.$http(request).then(function(response) {
        self.dietDays = response.data
      }).catch(()=>{
      }).finally(()=>{
        self.proportion =  self.dietDays / 30 * 100
      })
    },
    getMemberDietList(){
      var self = this
      var request = {
        method: 'POST',
        url: '/mycalMemberDietListByMemberId',
        data: this.Qs.stringify({
          memberId: self.memberId,
        }),
      }
      this.$http(request).then(function(response) {
        self.memberDietList = response.data.data
      }).catch(()=>{
        self.isError = true
      }).finally(()=>{
        for(var i=0; i<self.memberDietList.length; i++){
          if(parseInt(self.memberDietList[i].totalKcal)>parseInt(self.datasets.caloriesGoal)){
            self.$set(self.memberDietList[i], 'totalKcalType', true)
          }else{
            self.$set(self.memberDietList[i], 'totalKcalType', false)
          }
        }
        self.updateCalendarAttr()
      })
    },
    updateCalendarAttr(){
      // https://vcalendar.io/attributes.html

      for(var i=0; i<this.memberDietList.length; i++){
        if(moment(new Date(this.memberDietList[i].recordDate)).format('YYYY-MM-DD') < moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')){
          this.attrs.push({ //過期點數
            highlight: {
              color: 'gray',
              fillMode: 'light',
            },
            dates: new Date(this.memberDietList[i].recordDate),
          })
        }else{
          if(this.memberDietList[i].isUsed == '0'){
            this.attrs.push({ //未使用點數
              highlight: {
                color: 'blue', 
                fillMode: 'light',
              },
              dates: new Date(this.memberDietList[i].recordDate),
            })
          }else if(this.memberDietList[i].isUsed == '1'){
            this.attrs.push({ //已使用點數
              highlight: {
                color: 'green', 
                fillMode: 'light',
              },
              dates: new Date(this.memberDietList[i].recordDate),
            })
          }
        }
      }

      //current date (必須放最後)
      this.attrs.push({
        highlight: {
          color: 'red',
          fillMode: 'solid',
        },
        dates: new Date(),
      })
    },
    updateCalendarDate(day){
      // console.log("[updateCalendarDate] id: " + day.id)
      // console.log("[updateCalendarDate] date: " + day.date)
      // this.dateSelect = day.date
    },
  }
}
</script>

