<template>
  <div style="background:#FBFBFB">
    <div v-if="!haveToken">
      <v-row style="margin: 50px 10% 10px 10%" align="center" justify="center">
        <div align="center" @click="redirectPath('/homePage')">
          <v-img v-if="language != 'en'" class="finger" :src="images.logoTw" width=180 alt="MyCal-LOGO-TW"
          :style="{'margin-left' : (innerWidth >= 503) ? '0px' : '20px'}"></v-img>
          <v-img v-if="language == 'en'" class="finger" :src="images.logoEn" width=180 alt="MyCal-LOGO-TW"
          :style="{'margin-left' : (innerWidth >= 503) ? '0px' : '20px'}"></v-img>
        </div>
        <div class="app-content-left" style="margin-left: 30px">
          <v-row>
            <p v-if="innerWidth >= 1260" class="tab" :class="{tab_click:tabIndex==0}" style="" @click="redirectPath('/homePage'); tabIndex=0">{{$t('__homePage')}}</p>
            <p class="tab" :class="{tab_click:tabIndex==1}" style="" @click="redirectPath('/aboutMyCal'); tabIndex=1">{{$t('__aboutMyCal')}}</p>
            <p class="tab" :class="{tab_click:tabIndex==2}" style="" @click="redirectPath('/privacyPage'); tabIndex=2">{{$t('__privacyPage')}}</p>
            <p class="tab" :class="{tab_click:tabIndex==3}" style="" @click="redirectPath('/storageUpgrade'); tabIndex=3">{{$t('__storageUpgrade')}}</p>
            <p class="tab" :class="{tab_click:tabIndex==4}" style="" @click="confirmButton('/couponPage'); tabIndex=4">{{$t('__coupon')}}</p>
            <p class="tab" :class="{tab_click:tabIndex==5}" style="" @click="redirectPath('/contactUs'); tabIndex=5">{{$t('__contactUs')}}</p>
          </v-row>
        </div>
        <v-spacer v-if="innerWidth >= 363"></v-spacer>
        <v-menu v-if="innerWidth >= 390" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mx-1">
              <v-img :src="images.iconWeb" width=20 alt="icon-People"></v-img>
            </v-btn>
          </template>
          <v-list>
            <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'tw'">
              <v-list-item-title>{{$t('__languageTw')}}</v-list-item-title>
            </v-list-item>
            <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'cn'">
              <v-list-item-title>{{$t('__languageCn')}}</v-list-item-title>
            </v-list-item>
            <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'en'">
              <v-list-item-title>{{$t('__languageEn')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mx-2" @click="confirmButton(0)">
              <v-img :src="images.iconPeople" width=20 alt="icon-People"></v-img>
              <!-- <v-icon style="color:#4880C8">mdi-menu-down</v-icon> -->
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="!loginState" style="min-width: 100px;cursor: pointer" @click="$refs.LoginPage.open()">
              <v-list-item-title>{{$t('__login')}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loginState" style="min-width: 100px;cursor: pointer" @click="redirectPath('/storageUpgradeRecord')">
              <v-list-item-title>{{$t('__record')}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loginState" style="min-width: 100px;cursor: pointer" @click="redirectPath('/couponList')">
              <v-list-item-title>{{$t('__coupon')}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loginState" style="min-width: 100px;cursor: pointer" @click="logout()">
              <v-list-item-title>{{$t('__logout')}}</v-list-item-title>
            </v-list-item>
            <div v-if="innerWidth < 390">
              <h4 class="py-2" style="border-top:1px solid #4880C8; border-bottom:1px solid #4880C8; color:#4880C8">{{$t('__languageSetting')}}</h4>
              <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'tw'">
                <v-list-item-title>{{$t('__languageTw')}}</v-list-item-title>
              </v-list-item>
              <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'cn'">
                <v-list-item-title>{{$t('__languageCn')}}</v-list-item-title>
              </v-list-item>
              <v-list-item style="min-width: 100px; cursor: pointer" @click="language = 'en'">
                <v-list-item-title>{{$t('__languageEn')}}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-row>

      <div class="hamburger" style="width: 80px; display: none">
        <input id="menu__toggle" type="checkbox" v-model="hamburger_toggle"/>
        <label class="menu__btn" for="menu__toggle" style="z-index: 4; margin: 25px 50px 0px 0px">
          <span></span>
        </label>
        <ul class="menu__box" style="z-index: 3">
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==0}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/homePage'); tabIndex=0; hamburger_toggle=false">{{$t('__homePage')}}</p>
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==1}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/aboutMyCal'); tabIndex=1; hamburger_toggle=false">{{$t('__aboutMyCal')}}</p>
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==2}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/privacyPage'); tabIndex=2; hamburger_toggle=false">{{$t('__privacyPage')}}</p>
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==3}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/storageUpgrade'); tabIndex=3; hamburger_toggle=false">{{$t('__storageUpgrade')}}</p>
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==4}" style="box-shadow: 0 2px #DDD;" @click="confirmButton('/couponPage'); tabIndex=4; hamburger_toggle=false">{{$t('__coupon')}}</p>
          <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==5}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/contactUs'); tabIndex=5; hamburger_toggle=false">{{$t('__contactUs')}}</p>
          <!-- <p class="tab_hamburger py-4" :class="{tab_click:tabIndex==3}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/contactUs'); tabIndex=3; hamburger_toggle=false">聯絡我們</p> -->
          <!-- 
          <p class="tab_hamburger" :class="{tab_click:tabIndex==3}" style="box-shadow: 0 2px #DDD;" @click="redirectPath('/location'); tabIndex=3; hamburger_toggle=false">Venue</p> 
          <p class="tab_hamburger" :class="{tab_click:tabIndex==4}" style="" @click="redirectPath('/contactUs'); tabIndex=4; hamburger_toggle=false">Contacts</p>-->
        </ul>
      </div>
    </div>

    <div>
      <router-view />
    </div>
    <LoginPage ref="LoginPage" :onSave="onLoginPage"/>
    <ShowAnnouncement ref="ShowAnnouncement"/>
  </div>
</template>

<script>
import LoginPage from '@/dialogs/LoginPage'
import ShowAnnouncement from '@/dialogs/ShowAnnouncement'
import i18n from '@/plugins/i18n'
export default {
  name: 'FrontPage',
  components: {LoginPage,ShowAnnouncement},
  data:() => ({
    images:{
      logoTw: require('@/assets/image/logo_Tw.png'),
      logoEn: require('@/assets/image/logo_En.png'),
      iconPeople: require('@/assets/image/icon-people.png'),
      iconWeb: require('@/assets/image/icon-web.png'),
    },
    language:'tw',
    path:'',
    tabIndex:0,
    innerWidth: 0,
    hamburger_toggle:false,
    loginState: false,
    haveToken: false,
    announcement:{},
  }),
  computed: {},
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
  },
  mounted() {
    const currentHash = window.location.hash;
    if (currentHash === '#/') {
      this.redirectPath('/homePage');
    }
    this.tabIndex = 0
    this.appAuthProfile()
    if(this.$cookie.get('language')){
      this.language = this.$cookie.get('language')
    }
    setTimeout(() => { this.setLanguage(this.language); }, 100); //delay 0.1s
  },
  watch:{
    "language":function(){
      this.setLanguage(this.language)
      this.$root.$emit('language', this.language)
    },
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
      if(this.$cookie.get('token') || this.$cookie.get('token') == ''){
        this.haveToken = true
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    confirmButton(value){
      if(!sessionStorage.getItem('myCalToken')){
        this.loginState = false
        if(value != 0){
          this.$refs.LoginPage.open()
          this.path = value
        }
      }else{
        this.loginState = true
        if(value != 0){
          this.redirectPath(value)
        }
      }
    },
    appAuthProfile(){
      // console.log('[A1]appAuthProfile')
      var self = this
      // console.log(self.$cookie.get('token'))
      if(!self.$cookie.get('token')){
        self.checkAnnouncement()
        return
      }
      var request = {
        method: 'GET',
        url: '/common/appAuthProfile',
        params: {
          token: self.$cookie.get('token'),
          // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBMDAxIiwiaWF0IjoxNzA5MjU2Njc0LCJleHAiOjE3MTE4NDg2NzR9.JZWb9JYp4wirnvbuzzs3D05EPqaF5xtP5zEagOQZbSo',
        },
      }
      this.$http(request).then(function(response) {
        // console.log('[A1]appAuthProfile-response')
        if(response.data.statusCode != 401){
          self.login(response.data.memberId, response.data.email)
        }
      }).catch(()=>{
        self.checkAnnouncement()
      }).finally(()=>{
      })
    },
    checkAnnouncement(){
      console.log(this.$cookie.get('myCookie'))
      if (new Date()<new Date(this.$cookie.get('myCookie'))) {
        console.log('今日不再提示')
        return
      }

      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/MaintenanceAnnouncement',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.announcement = []
        self.announcement = response.data.data[0]
        if(self.announcement.note1){
          self.announcement.note1 = JSON.parse(self.announcement.note1)
        }
      }).catch(()=>{
      }).finally(()=>{
        if(new Date(self.announcement.note1.startTime)<new Date() && new Date()<new Date(self.announcement.note1.endTime)){
          let data = ''
          if(self.language=='tw'){
            data = self.announcement.twContent
          }else if(self.language=='cn'){
            data = self.announcement.cnContent
          }else if(self.language=='en'){
            data = self.announcement.enContent
          }
          self.$refs.ShowAnnouncement.open(data)
        }
      })
    },
    login(memberId,email){
      // console.log('[A2]login')
      var self = this
      var request = {
          method: 'POST',
          url: '/mycalAppMemberLogin',
          data: this.Qs.stringify({
            memberId: memberId,
            email: email,
            appToken: self.$cookie.get('token'),
            // appToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBMDAxIiwiaWF0IjoxNzA5MjU2Njc0LCJleHAiOjE3MTE4NDg2NzR9.JZWb9JYp4wirnvbuzzs3D05EPqaF5xtP5zEagOQZbSo',
          }),
      }
      this.$http(request).then(function(response) {
        // console.log('[A2]message: ' + response.data.data)
        if(response.data.data.message == '登入成功'){
          sessionStorage.setItem('myCalToken', response.data.data.token)
          sessionStorage.setItem('myCalName',response.data.data.memberName)
          sessionStorage.setItem('myCalId',response.data.data.memberId)
          sessionStorage.setItem('myCalEmail',response.data.data.email)
          sessionStorage.setItem('myCalExpiration',response.data.data.expiration)
          self.$root.$emit('setSessionStorage', true)
        }
      }).catch(()=>{
        self.isError = true
      })
    },
    logout(){
      sessionStorage.removeItem('myCalToken')
      sessionStorage.removeItem('myCalName')
      sessionStorage.removeItem('myCalId')
      sessionStorage.removeItem('myCalEmail')
      sessionStorage.removeItem('myCalExpiration')
      sessionStorage.removeItem('totalDietDays')
      this.redirectPath('/homePage')
    },
    setLanguage(language){
      switch(language){
        case 'tw':
        case 'cn':
        case 'en':
          i18n.locale = language
          // localStorage.setItem('locale', language)
          // this.$cookie.set('language', language, 30)
          break
        default:
          break
      }
    },
    onLoginPage(){
      this.redirectPath(this.path)
    },

  }
}
</script>

<style>
  .tab, .tab_hamburger {
    font-size: 18px;
    font-weight: 700;
    color: #737373;
    cursor: pointer;
  }
  .tab_click {
    /* color: #4880C8; */
  }
  .tab:hover {
    /* box-shadow: 0 2px #133755; */
    color: #4880C8;
  }
  .tab_hamburger:hover {
    color: #4880C8;
  }

  /** hamburger start **/
  /* https://codepen.io/alvarotrigo/pen/yLzaPVJ */
  #menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }
  .menu__btn {
    position: fixed;
    top: 40px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    /* z-index: 1; */
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #4880C8;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 230px;
    /* height: 100%; */
    margin: 0;
    padding-left: 0px !important; 
    padding-top: 65px;
    list-style: none;
    background-color: #FFF;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
  }
  /** hamburger end **/

  @media (max-width: 1020px) {
    .hamburger {
      display: unset !important;
      width: 50px !important;
    }
    .app-content-left {
      display: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .tab, .tab_hamburger {
      padding: 10px 15px;
    }
  }
  @media (max-width: 1199px) and (min-width: 1090px) {
    .tab, .tab_hamburger {
      padding: 10px 9px;
    }
  }
  @media (max-width: 1089px) {
    .tab, .tab_hamburger {
      padding: 10px 4px;
    }
  }

</style>