<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row style="justify-content:center" :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '0% 0%'}]">
        <v-col class="col-12 col-lg-6 px-0" align="center">
          <h4 class="styleH4">{{$t('__storageUpgrade')}}</h4>
          <div id="box">
            <v-row class="my-1 px-5 text-left weight600">
              <div v-html="content" style="width:100%; max-height:360px; overflow-y:auto"></div>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row style="justify-content:center" :style="[{'margin' : (innerWidth > 959) ? '1% 8% 5% 8%' : '0% 0%'}]">
        <v-col class="col-12 col-md-6 col-lg-3 pa-0" align="center">
          <v-btn class="btnFlex btnGradient ma-1" style="height:126px; width:90%" 
          :style = "[{'box-shadow' : (indexHover == 1) ? '3px 3px 3px 3px #E6E6E6' : ''}]"
          @mouseover="indexHover = 1" @mouseleave="indexHover = 0" @click="confirmButton('/storageUpgradeApply')">
            <v-img v-show="language != 'en'" :src="images.iconMoney" width=60 alt="MyCal-iconMoney"></v-img>
            <v-col class="pa-0 pl-2">
              <h5 class="pa-0 weight600" :style = "[{'font-size' : (language == 'en') ? '16px' : '24px'},{'margin-top' : (language == 'en') ? '6px' : '0px'}]">{{$t('__applicationButton1')}}</h5>
              <h5 class="pa-0 weight600" :style = "[{'font-size' : (language == 'en') ? '16px' : '24px'},{'margin-top' : (language == 'en') ? '6px' : '0px'}]">{{$t('__applicationButton2')}}</h5>
            </v-col>
          </v-btn>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-3 pa-0" align="center">
          <v-btn class="btnFlex btnGradient ma-1" style="height:126px; width:90%" 
          :style = "[{'box-shadow' : (indexHover == 2) ? '3px 3px 3px 3px #E6E6E6' : ''}]"
          @mouseover="indexHover = 2" @mouseleave="indexHover = 0" @click="confirmButton('/storageUpgradeRecord')">
            <v-img v-show="language != 'en'" :src="images.iconPaper" width=60 alt="MyCal-iconPaper"></v-img>
            <v-col class="pa-0 pl-2">
              <h5 class="pa-0 weight600" :style = "[{'font-size' : (language == 'en') ? '16px' : '24px'},{'margin-top' : (language == 'en') ? '6px' : '0px'}]">{{$t('__recordButton1')}}</h5>
              <h5 class="pa-0 weight600" :style = "[{'font-size' : (language == 'en') ? '16px' : '24px'},{'margin-top' : (language == 'en') ? '6px' : '0px'}]">{{$t('__recordButton2')}}</h5>
            </v-col>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <LoginPage ref="LoginPage" :onSave="onLoginPage"/>
  </div>
</template>

<script>
import LoginPage from '@/dialogs/LoginPage'
import i18n from '@/plugins/i18n'
export default {
  name: 'StorageUpgrade',
  components: {LoginPage},
  mounted(){
    this.language = i18n.locale
    this.getDataset()
  },
  data:() => ({
    language:'',
    datasets:[],
    haveToken: false,
    innerWidth: 0,
    indexHover:'',
    path:'',
    images:{
      background: require('@/assets/image/background-2000.png'),
      iconMoney: require('@/assets/image/icon_money.png'),
      iconPaper: require('@/assets/image/icon_paper.png'),
    },
    content:'',
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token') || this.$cookie.get('token') == ''){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.datasets.twContent
      }else if(this.language=='cn'){
        this.content = this.datasets.cnContent
      }else if(this.language=='en'){
        this.content = this.datasets.enContent
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgrade',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    confirmButton(value){
      if(!sessionStorage.getItem('myCalToken')){
        this.path = value
        this.$refs.LoginPage.open()
      }else{
        this.redirectPath(value)
      }
    },
    onLoginPage(){
      this.redirectPath(this.path)
    },
  }
}
</script>

