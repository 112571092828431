import Vue from 'vue'
import VueRouter from 'vue-router'
import FrontPage from '../views/FrontPage.vue'
import HomePage from '../views/HomePage.vue'
import HomePageDetail from '../views/HomePageDetail.vue'

import AboutMyCal from '../views/AboutMyCal.vue'
import PrivacyPage from '../views/PrivacyPage.vue'
import PrivacyApply from '../views/PrivacyApply.vue'
import StorageUpgrade from '../views/StorageUpgrade.vue'
import StorageUpgradeApply from '../views/StorageUpgradeApply.vue'
import StorageUpgradeRecord from '../views/StorageUpgradeRecord.vue'
import CouponPage from '../views/CouponPage.vue'
import CouponInfo from '../views/CouponInfo.vue'
import CouponDetail from '../views/CouponDetail.vue'
import CouponList from '../views/CouponList.vue'
import ContactUs from '../views/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage,
    children: [
      {
        path: '/homePage',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: '/homePageDetail',
        name: 'HomePageDetail',
        component: HomePageDetail
      },
      {
        path: '/aboutMyCal',
        name: 'AboutMyCal',
        component: AboutMyCal
      },
      {
        path: '/privacyPage',
        name: 'PrivacyPage',
        component: PrivacyPage
      },
      {
        path: '/privacyApply',
        name: 'PrivacyApply',
        component: PrivacyApply
      },
      {
        path: '/storageUpgrade',
        name: 'StorageUpgrade',
        component: StorageUpgrade
      },
      {
        path: '/storageUpgradeApply',
        name: 'StorageUpgradeApply',
        component: StorageUpgradeApply
      },
      {
        path: '/storageUpgradeRecord',
        name: 'StorageUpgradeRecord',
        component: StorageUpgradeRecord
      },
      {
        path: '/couponPage',
        name: 'CouponPage',
        component: CouponPage
      },
      {
        path: '/couponInfo',
        name: 'CouponInfo',
        component: CouponInfo
      },
      {
        path: '/couponDetail',
        name: 'CouponDetail',
        component: CouponDetail
      },
      {
        path: '/couponList',
        name: 'CouponList',
        component: CouponList
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs
      },
    ]
  },

  {path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes
})

export default router
