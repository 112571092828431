import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n'
import * as VueGoogleMaps from 'vue2-google-maps';

import global from './global.js'
Vue.prototype.$global = global;

import Global from './Global.vue'
Vue.prototype.$Global = Global
Vue.config.productionTip = false

var VueCookie = require('vue-cookie')
Vue.use(VueCookie)

import '@/assets/css/Main.css'
import '@/assets/icomoon/style.css'

import { CONFIG } from '../config'
import Qs from 'qs'
Vue.prototype.Qs = Qs
import axios from 'axios'
Vue.prototype.$http = axios

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers["Access-Control-Allow-Origin"]="*"
  // console.log("axios.interceptors = ",config.headers['Content-Type'])
  
  if(!config.headers['Content-Type']){
    config.headers['Content-Type']='application/x-www-form-urlencoded'  
  }

  if(config.url.includes("TrustLink")) {
    config.url = 'https://testtrustlink.hitrust.com.tw' + config.url;
  } else {
  if(!config.url.includes("mycalMemberLogin") && !config.url.includes("mycalAppMemberLogin") && !config.url.includes("common")) {
    config.headers["token"]= sessionStorage.getItem('myCalToken')
  }
  config.url = CONFIG.API_URL+config.url;
}
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data.code == '990') {
    alert(response.data.msg)
    location.href = location.origin+location.pathname + '#/login'
  }
  return response;
}, function (error) {
  // Do something with response error
  if(error&&error.response&&error.response.data.State=="999"){
    //alert(error.response.data.ErrorMessage)
  if(!location.hash.includes("login")) {
    alert("使用者連線逾時或尚未登入, 請重新登入")
  }
    location.href = location.origin+location.pathname + '#/login'
  }
  return Promise.reject(error);
});

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC-U-a8MbyizbkPO6xO3OF6By9obayf2JQ',
    libraries: 'places', 
    language: 'en',
  },
  installComponents: true
},)