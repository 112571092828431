<template>
  <v-row justify="center">
    <v-dialog v-model="active" max-width="340">      
      <v-card class="pa-3">
        <div class="close-dialog-btn">
          <v-icon color="#D8DDE4" @click="close">mdi-close</v-icon>
        </div>        
        <v-card-title class="text-h5 my-1" style="font-weight:800">
          {{title}}
        </v-card-title>
        <v-card-text class="text-left mt-1" style="font-weight:600; min-height:80px; font-size:15px">
          {{content}}
        </v-card-text>             
        <v-card-actions class="px-3" style="justify-content:center">
          <v-btn outlined color="#4880C8" style="width: 122px; height:46px; border-radius: 10px;" @click="close">
            {{$t('__cancel')}}
          </v-btn>
          <v-btn outlined color="#FFF" style="width: 122px; height:46px; border-radius: 10px; background:#4880C8" @click="save">
            {{$t('__confirm')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from '../plugins/i18n'
  export default {
    name:'ShowConfirm',
    props:['onSave'],
    data () {
      return {
        active: false,
        title: '',
        content: '',
        onSaveEn: false,
        info: '',
      }
    },
    methods:{
      open(type = null, title = null, content = null, onSaveEn = false, info = null){
        this.active = true
        this.onSaveEn = onSaveEn
        this.info = info
        switch(type){
          case 0:
          default:
            this.title = title
            this.content = content
            break
          case 1:
            this.title = i18n.t('__couponConfirmTitle')
            this.content = i18n.t('__couponConfirmContent')
            break
          case 2:
            this.title = i18n.t('__deleteConfirmTitle')
            this.content = i18n.t('__deleteConfirmContent')
            break
          case 4:
            this.title = i18n.t('__enterPassword')
            this.content = i18n.t('__enterPasswordContent')
            break
          case 5:
            this.title = i18n.t('__privacyConfirmTitle1')
            this.content = i18n.t('__privacyConfirmContent1')
            break
          case 6:
            this.title = i18n.t('__privacyConfirmTitle2')
            this.content = i18n.t('__privacyConfirmContent2')
            break
        }
      },
      close(){
        this.active = false
      },
      save(){
        if(this.onSaveEn){
          this.onSave(this.info)
        }
        this.close()
      }
    }
  }
</script>