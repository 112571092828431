/* /cn/lang.js */
export default {
  __homePage: '首页',
  __aboutMyCal: '关于我的卡路里',
  __privacyPage: '隐私权服务',
  __storageUpgrade: '加值服务',
  __couponExchange: '红利兑换',
  __contactUs: '联络我们',
  __languageTw: '繁中',
  __languageCn: '简中',
  __languageEn: '英文',
  __login: '登入',
  __logout: '登出',
  __record: '加值纪录',
  __coupon: '优惠券',
  __myCouponList: '我的优惠券',

  // footer homePage
  __companyDetail: '我的卡路里 | 详细介绍',
  __companyName: '瑞宝智能股份有限公司',
  __unifiedBusinessNo: '公司统编：89132638',
  __companyAddress: '登记地址：新北市板桥区馆前东路20号9楼',
  __companyEmail: '联络我们：service@rainbowapps.asia',
  __copyright: 'Copyright © 2024 瑞宝智能股份有限公司 版权所有',

  // universal
  __languageSetting: '语系选择',
  __select:'请选择',
  __enter:'请输入',
  __selectFile: '选择档案',
  __createTime: '建立时间',
  __updateTime: '修改时间',
  __completeInformation: '完整资料',
  __content:'內容',
  __back:'返回',
  __save: '储存',
  __new: '新增',
  __confirm: '确定',
  __cancel: '取消',
  __settings: '设定',
  __keywordSearch: '关键字查询',
  __categorySearch: '依据类别查询',
  __edit: '编辑',
  __total: '共',
  __total2: '笔',
  __go: '前往',
  __page: '页',
  __announcementTitle: '系统公告',
  __announcementCheckbox: '今日不再提醒。 ',
  
  // LoginPage
  __memberLogin: '会员登入',
  __managerUserId: '帐号',
  __managerEmail: '信箱',
  __enterUserId: '请输入使用者名称',
  __enterUserEmail: '请输入信箱',
  __managerPassword: '密码',
  __entermanagerPassword: '请输入密码',
  __isError: '帐号或密码输入错误 !',

  // aboutMyCal
  __operationBase: '运营据点',
  __linkSingapore: '新加坡',
  __linkSingaporeContent: '亚太区业务和市场开发中心',
  __linkTaiwan: '台湾',
  __linkTaiwanContent: '系统开发和维护中心',
  __linkChina: '中国',
  __linkChinaContent: '运营发展中心',
  __linkMalaysia: '马来西亚',
  __linkMalaysiaContent: '市场发展中心',
  __linkCambodia: '柬埔寨',
  __linkCambodiaContent: '运营发展中心',
  __linkPhilippines: '菲律宾',
  __linkPhilippinesContent: '市场发展中心',

  // ShowMsg
  __requiredTitle: '资讯不完整',
  __requiredContent: '请输入所有必填',
  __privacyApplyContent: '请确认要撤回的项目',
  __newTitle: '已新增资料',
  __newContent: '系统已新增此笔资料',
  __revisedTitle: '已修改资料',
  __revisedContent: '系统已修改此笔资料',
  __deleteTitle: '已删除资料',
  __deleteContent: '系统已删除此笔资料',
  __importTitle: '已汇入',
  __importContent: '系统已将资料汇入!',
  __sendTitle: '送出成功',
  __sendContent: '诚挚感谢您的询问，我们将尽速以电子邮件回应您。',
  __applyTitle: '申请成功',
  __applyContent: '感谢您的申请。收到付款后，将处理您的申请。',
  __couponTitle: '兑换成功',
  __couponContent: '感谢您的兑换，请于期限内使用。',
  __couponTitle2: '兑换成功',
  __couponContent2: '感谢您的兑换，请持续使用我的卡路里。',
  __couponTitle3: '兑换失败',
  __couponContent3: '您尚未累积三十天红利，请继续加油。 ',
  __privacyTitle: '撤回成功',
  __privacyContent: '系统已将您的个人资料撤回。',
  __loginTitle: '登入失败',
  __loginContent: '该帐号已停用，无法登入。',
  __imageTitle: '图片不符合规定',
  __imageContent: '请确认图片小于50KB，且图片宽度750像素，高度388像素。',
  __imageSizeTitle: '图片不符合规定',
  __imageSize50Content: '请确认图片小于50KB。',
  __imageSize80Content: '请确认图片小于80KB。',

  // ShowConfirm
  __couponConfirmTitle: '确定兑换',
  __couponConfirmContent: '您确定要进行兑换吗 ? 兑换后即可在优惠期间内至店家进行使用。',
  __deleteConfirmTitle: '确定删除',
  __deleteConfirmContent: '您确定要删除此笔资料吗 ?',
  __privacyConfirmTitle1: '确定撤回',
  __privacyConfirmContent1: '您确定要进行撤回吗 ? 撤回后系统将不会使用您的资料进行后期与医疗相关之个人资讯研究，该帐号仍可持续使用该应用程式。',
  __privacyConfirmTitle2: '确定撤回',
  __privacyConfirmContent2: '您确定要进行撤回吗 ? 撤回后系统将移除您的个人用户资讯、餐点纪录、优惠券等纪录。 请注意：点击「确定」您将无法再登入系统使用任何功能。',

  // CouponDialogs
  __enterPassword: '兑换优惠券',
  __enterPasswordContent: '您是否确定要兑换优惠券？点击「确定」后将进行兑换，且无法进行还原。',
  __enterPasswordError: '兑换密码错误',
  __enterPasswordSuccess: '兑换',

  // contactUs
  __commonProblem: '常见问题',
  __contactQuestion: '洽询问题',
  __contactName: '姓名',
  __contactEmail: '电子邮箱',
  __contactQuestionType: '问题类型',
  __contactQuestionContent: '问题内容',
  __contactQuestionPictures: '附加图片',
  __sendQuestions: '问题送出',

  // privacyPage
  __privacyConsent: '隐私权同意书',
  __privacyConsentWithdraw: '隐私权同意书撤回',
  __privacyQuestions: '若您对隐私权同意书有任何疑问，请',
  __privacyWithdraw: '若您希望撤回您的隐私权同意，请',
  __privacyWithdrawClick: '点此撤回',
  __applicantInformation: '申请者资讯',
  __memberName: '使用者名称',
  __memberEmail: '电子邮箱',
  __privacyWithdrawItem1: '仅不同意个人资讯，可能用于与健康相关的研究，但该帐号仍可继续使用本APP',
  __privacyWithdrawItem2: '不同意整份隐私权同意书，并同时注销个人账号及使用记录',
  __confirmWithdrawal: '确定撤回',

  // storageUpgrade storageUpgradeApply
  __applicationButton1: '申请加值',
  __applicationButton2: '服务按钮',
  __memberExpiration: '会员到期日',
  __recordButton1: '加值纪录',
  __recordButton2: '查看按钮',
  __pricePurchasePlan: '购买方案',
  __priceOrderProgram: '选购方案',
  __priceProgramFee: '方案费用',
  __pricePeriod: '方案期间',
  __priceStartDate: '起始日期',
  __priceEndDate: '结束日期',
  __pricePaymentMethod: '付款方式',
  __pricePaymentfrequency: '续约模式',
  __priceImmediatePayment: '立即付款',
  __priceOrderDescription: '方案说明：费用包含会员使用及储存个人卡路里/选餐记录期限。',

  __orderRecord: '我的卡路里加值纪录',
  __orderRecordMsg: '您目前还没有任何加值纪录',
  __orderProgramy: '加值方案',
  __paymentMethod: '付款方式',
  __paymentStatus: '缴费状态',
  __orderNumber: '加值单号',
  __orderMemberId: '申请对象',
  __orderCreateTime: '申请时间',
  __programFee: '方案费用',
  __orderUpdateTime: '异动时间',
  __paymentTime: '付款时间',

  // CouponList couponDetail couponInfo
  __couponHello: '您好',
  __couponHelloMsg1: '已累计使用',
  __couponHelloMsg2: '天',
  __showDetail: '点击查看详情',
  __alart1: '温馨提示：请记得兑换优惠券',
  __alart2: '请持续努力将可兑换优惠券',
  __couponUse: '使用',
  __couponAvailable: '可使用',
  __couponUsed: '已使用',
  __couponExpired: '已过期',
  __redemptionNumber: '兑换单号',
  __redemptionProgram: '兑换方案',
  __manufacturerName: '厂商名称',
  __productName: '商品名称',
  __availablePeriod: '兑换时间',
  __usageInterval: '使用区间',
  __useButton: '使用按钮',
  __remainingStock: '剩余库存',
  __btnContinue: '持续加油',
  __btnRedeem: '点击兑换',
  __introductionProduct: '商品介绍',
  __introductionStore: '店家介紹',
  __couponInfoBtn1: '兑换机制',
  __couponInfoBtn2: '使用状态',
  __couponInfoBtn3: '兑换纪录',
  __couponList: '优惠券状态',
  __functionMenu: '功能选单',
  __couponAll: '全选',
  __couponClear: '清空',
  __calendarDateInfo1: '已过期',
  __calendarDateInfo2: '已兑换',
  __calendarDateInfo3: '尚未兑换',
  __calendarDateInfo4: '今日',
}