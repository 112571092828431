<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row style="justify-content:center" :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '1% 1%'}]">
        <v-col class="col-12 col-md-6 px-4" align="center">
          <h4 class="styleH4">{{$t('__storageUpgrade')}}</h4>
          <v-row class="my-1 pa-3 text-left weight600">
            <div v-html="upgradeInfo" style="width:100%"></div>
          </v-row>
          <div id="box" class="mb-0" style="width:100%">
            <v-row class="pa-3 pb-8">
              <v-icon class="ma-0 pa-0" size="30" style="color:#4880C8">mdi-menu-right</v-icon>
              <h4 class="DarkBlue pt-1">{{$t('__pricePurchasePlan')}}</h4>
            </v-row>
            <v-col class="pa-0">
              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__memberExpiration')" v-model="myCalExpiration">
              </v-text-field>

              <v-radio-group :label="$t('__priceOrderProgram')" v-if="upgradeDetailList.language != 0" 
                class="ma-0" dense v-model="datasets.orderProgram">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in upgradeDetailList" :key = "index" :value = "data.useMonth.toString()"
                  class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.title}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>

              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__priceProgramFee')" v-model="datasets.programFee">
              </v-text-field>

              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__pricePeriod')" v-model="usedDatesInfo">
              </v-text-field>

              <!-- <v-row class="ma-0">
                <v-col class="px-0 col-2">
                  <div class="text-right pt-2 pt14">{{$t('__pricePeriod')}}</div>
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-4">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="datasets.startDate"></v-text-field>
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-1" align-self="center" style="text-align: center">
                  ~
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-4">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="datasets.endDate"></v-text-field>
                </v-col>

                <v-col v-if="innerWidth < 500" class="col-9">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="usedDatesInfo"></v-text-field>
                </v-col>
              </v-row> -->

              <v-radio-group :label="$t('__pricePaymentMethod')" class="ma-0" dense v-model="datasets.paymentMethod">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in paymentList" :key = index :value = "data.valuev"
                  class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.content}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>
              <v-radio-group :label="$t('__pricePaymentfrequency')" class="ma-0" dense v-model="datasets.frequency">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in frequencyList" :key = index :value = "data.valuev"
                  class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.content}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>
              <v-row class="ma-0" style="justify-content:center">
                <v-btn class="styleBtn1 border" outlined style="max-width:50%; color:#FFF; background:#4880C8" @click="newDataset()">
                  <h5 class="center">{{$t('__priceImmediatePayment')}}</h5>
                </v-btn>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-5 px-4">
          <v-row v-for="(data,index) in upgradeDetailList" :key="index" class="mx-0 my-3 pa-0" style="border: 2px solid #4880C8; border-radius: 8px;">
            <div class="text-center ma-0 pa-4 pa-auto" style="position:relative; background:#4880C8; color:#FFF"
            :style="{'width' : (innerWidth >= 490) ? '160px' : '96px'}">
              <v-row class="ma-0 pr-2" style="width:100%" justify="center">
                <h2 style="width:70px; line-height:36px" :style="{'font-size' : (language=='en') ? '1em' : '1.5em'}">{{data.title}}</h2>
              </v-row>
              <div v-if="innerWidth >= 490" style="width:76px; position:absolute; top:13px; left:122px">
                <v-img :src="images['img'+(index+1)]"></v-img>
              </div>
            </div>
            <v-col class="text-center ma-0 pa-0" align-self="center">
              <!-- <h3 class="" style="line-height:28px">{{$t('__priceOrderProgram')}}</h3> -->
              <h3 style="line-height:28px">{{data.name}} {{data.currency}} {{data.amount}}</h3>
            </v-col>
          </v-row>
          <h4 class="text-left weight600">{{$t('__priceOrderDescription')}}</h4>
        </v-col>
      </v-row>
    </div>
    <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
      <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
      @click="redirectPath('/storageUpgrade')">
        <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
      </div>
      <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
    </div>
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'storageUpgradeApply',
  props: {},
  components: {ShowMsg,Loading},
  mounted(){
    this.language = i18n.locale
    this.getStorageUpgradeInfo()
    this.getStorageUpgradeDetail()
    this.getPaymentList()
    this.getFrequencyList()
    this.getExpirationList()
  },
  data:() => ({
    language:'',
    orderProgram:'',
    datasets:{},
    haveToken: false,
    myCalExpiration:'',
    usedDatesInfo:'',
    upgradeInfo:'',
    upgradeInfoList:[],
    upgradeDetail:[],
    upgradeDetailList:[],
    paymentList:[],
    frequencyList:[],
    innerWidth: 0,
    indexHover:'',
    images:{
      background: require('@/assets/image/background-2000.png'),
      img1: require('@/assets/image/img1.png'),
      img2: require('@/assets/image/img2.png'),
      img3: require('@/assets/image/img3.png'),
      img4: require('@/assets/image/img4.png'),
    },
    isLoading: false,
    content:'',

    menu: false,
    // pickerDate:(new Date().getFullYear()) + '-1-1',
    requiredField:['orderProgram','programFee','startDate','endDate','paymentMethod'],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
      this.changeDatasetSel()
      this.changeListSel(this.paymentList)
      this.changeListSel(this.frequencyList)
    },
    "datasets.orderProgram":function(){
      let value = this.datasets.orderProgram
      var index = this.upgradeDetailList.findIndex(function(item){return item.useMonth == value})
      if(index != -1){
        this.datasets.programFee = this.upgradeDetailList[index].amount
      }
      this.usedDates()
    },
    "datasets.startDate":function(){
      this.usedDates()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    allowedDates(val){
      // console.log('[allowedExamDates] date: ' + val)
      let dateMin = moment().add(0, 'days').format('YYYY-MM-DD')
      return (new Date(val) >= new Date(dateMin))
    },
    getExpirationList(){
      var allData = this.$Global.copy(this.datasets)
      allData = this.$Global.jsonConcat(allData,{'memberId': sessionStorage.getItem('myCalId')})
      var self = this
      var request = {
        method: 'POST',
        url: '/mycalMemberExpiration',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        self.myCalExpiration = response.data.msg
        // self.$refs.ShowMsg.open(7,'','',true,true)
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Cot-8121')
      }).finally(()=>{
        if(new Date() >= new Date(self.myCalExpiration)){
          this.datasets.startDate = moment(new Date()).format('YYYY-MM-DD')
        }else{
          this.datasets.startDate = self.myCalExpiration
        }
      })
    },
    usedDates(){
      if(!this.datasets.orderProgram || !this.datasets.startDate){
        return
      }
      let value = this.datasets.orderProgram
      var index = this.upgradeDetailList.findIndex(function(item){return item.useMonth == value})
      if(index != -1){
        var months = this.upgradeDetailList[index].useMonth.toString()
      }
      this.datasets.endDate = moment(this.datasets.startDate).add(months, 'months').format('YYYY-MM-DD')
      this.usedDatesInfo = this.datasets.startDate + ' ~ ' + this.datasets.endDate
    },
    changeListSel(List){
      for(var i=0; i<List.length; i++){
        if(this.language=='tw'){
          this.$set(List[i], 'content', List[i].twName)
        }else if(this.language=='cn'){
          this.$set(List[i], 'content', List[i].cnName)
        }else if(this.language=='en'){
          this.$set(List[i], 'content', List[i].enName)
        }
      }
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.upgradeInfo = this.upgradeInfoList.twContent
      }else if(this.language=='cn'){
        this.upgradeInfo = this.upgradeInfoList.cnContent
      }else if(this.language=='en'){
        this.upgradeInfo = this.upgradeInfoList.enContent
      }
    },
    changeDatasetSel(){
      if(!this.upgradeDetail.twContent){
        return
      }
      if(this.language=='tw'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.twContent)
      }else if(this.language=='cn'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.cnContent)
      }else if(this.language=='en'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.enContent)
      }
    },
    getStorageUpgradeInfo(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgradeInfo',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.upgradeInfoList = []
        self.upgradeInfoList = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    getStorageUpgradeDetail(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgradeDetail',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.upgradeDetail = []
        self.upgradeDetail = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeDatasetSel()
      })
    },
    getPaymentList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/paymentMethod',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.paymentList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        self.changeListSel(self.paymentList)
      })
    },
    getFrequencyList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/frequency',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.frequencyList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        self.changeListSel(self.frequencyList)
      })
    },
    newDataset() {
      if(!this.$Global.checkForm(this.datasets,this.requiredField)){
        this.$refs.ShowMsg.open(1)
        return
      }
      this.isLoading = true
      var allData = this.$Global.copy(this.datasets)
      allData = this.$Global.jsonConcat(allData,{'languageSystem': this.language})
      allData = this.$Global.jsonConcat(allData,{'memberId': sessionStorage.getItem('myCalId')})
      allData = this.$Global.jsonConcat(allData,{'paymentStatus': '1'})
      allData = this.$Global.jsonConcat(allData,{'note1': sessionStorage.getItem('myCalEmail')})
      // console.log(allData)
      var self = this
      var request = {
        method: 'POST',
        url: '/paymentRecord',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        self.$refs.ShowMsg.open(7,'','',true,true)
        self.isLoading = false
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Cot-7957')
        self.isLoading = false
      }).finally(()=>{
      })
    },
    onShowMsg(){
      this.redirectPath('/storageUpgrade')
    },
  }
}
</script>

