import axios from 'axios'

export default {
  
  // this.$global.globalFunctionTest();
  globalFunctionTest(){
    console.log('[global] globalFunctionTest')
  },

  // this.countryList = this.$global.getCountryList()
  getCountryList(){
    console.log('[global] getCountryList')
    let res = []
    var request = {
      method: 'GET',
      url: '/countryList',
      params: {},
    }
    axios(request)
      .then(function(response) {
        console.log("[global] response: " + response)
        res = response.data.data
        console.log("[global] res: " + res)
        console.log('[global] country: ' + res[0].country)
        console.log('[global] countryIndex: ' + res[0].countryIndex)
        return res //unable to return this value
      }).catch(()=>{
      }).finally(()=>{
      })

    console.log("[global][last] res: " + res)
    return res //run faster than response

    // setTimeout(() => {
    //   console.log("[global][last] res: " + res)
    //   return res
    // }, 1000); //delay 1s
  },
}