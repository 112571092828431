<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row justify-content="center"  :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '0% 0%'}]">
        <v-col class="col-12 col-md-6 my-auto" align="center"> 
          <v-img :src="images.privacyPage" width=600 alt="action_04"></v-img>
        </v-col>

        <v-col class="col-12 col-md-6 px-0" align="center">
          <h4 class="styleH4">{{$t('__privacyConsent')}}</h4>
          <div id="box">
            <v-row class="my-1 px-6 text-left weight600" style="height:480px; overflow-y:scroll">
              <div v-html="content" style="width:100%"></div>
            </v-row>
          </div>
          <h4 v-if="!haveToken">{{$t('__privacyQuestions')}} <a style="color:#4880C8; text-decoration:underline" @click="redirectPath('/contactUs')">{{$t('__contactUs')}}</a></h4>
          <h4>{{$t('__privacyWithdraw')}} <a style="color:#4880C8; text-decoration:underline" @click="confirmButton()">{{$t('__privacyWithdrawClick')}}</a></h4>
        </v-col>
      </v-row>
    </div>
    <LoginPage ref="LoginPage" :onSave="onLoginPage"/>
  </div>
</template>

<script>
import LoginPage from '@/dialogs/LoginPage'
import i18n from '@/plugins/i18n'
export default {
  name: 'PrivacyPage',
  components: {LoginPage},
  mounted(){
    this.language = i18n.locale
    this.getDataset()
  },
  data:() => ({
    language:'',
    datasets:[],
    haveToken: false,
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
      privacyPage: require('@/assets/image/privacyPage.png'),
    },
    content:'',
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token') || this.$cookie.get('token') == ''){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    confirmButton(){
      if(!sessionStorage.getItem('myCalToken')){
        this.$refs.LoginPage.open()
      }else{
        this.redirectPath('/privacyApply')
      }
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.datasets.twContent
      }else if(this.language=='cn'){
        this.content = this.datasets.cnContent
      }else if(this.language=='en'){
        this.content = this.datasets.enContent
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/PrivacyContent',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    onLoginPage(){
      this.redirectPath('/privacyApply')
    },
  }
}
</script>

