<template>
  <v-row class="ma-0" style="justify-content:center">
    <v-dialog v-model="active" persistent max-width="400">
      <v-card class="pa-3">
        <div class="close-dialog-btn ma-3" style="border-radius:14px">
          <v-icon size="28px" color="#D8DDE4" @click="close">mdi-close</v-icon>
        </div>
        <v-row class="ma-0" style="padding:0px 24px">
          <v-card-title class="text-h5 my-2 px-0" style="font-weight:800">
            <div class="mr-3" style="background:#4880C8; width: 11px; height: 38px;"></div>
            <span class="mt-1">{{$t('__memberLogin')}}</span>
          </v-card-title>
        </v-row>
        <div style="width:100%; padding:0px 24px">
          <div class="text-left pt14">{{$t('__managerEmail')}}</div>
          <v-text-field class="border pt-1 pt14" dense background-color="white" outlined :placeholder="$t('__enterUserEmail')" v-model="email"></v-text-field>
          <div class="text-left pt-2 pt14">{{$t('__managerPassword')}}</div>
          <v-text-field class="border pt-1 pt14" dense background-color="white" outlined :placeholder="$t('__entermanagerPassword')" type="password" v-model="passwd" @keyup.enter="login()"></v-text-field>
          <h4 class="pt-4 text-left" style="font-size:14px; color:#FF5A5A" v-if="isError">{{$t('__isError')}}</h4>
          <v-btn class="loginBack mt-5 mb-10" outlined style="height:40px; width:100%; background:#4880C8; border-radius:8px; border:none" @click="login()">
            <span style="color:#FFFFFF">{{$t('__login')}}</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <ShowMsg ref="ShowMsg"/>
  </v-row>
</template>

<script>
  import ShowMsg from '@/dialogs/ShowMsg'
  import CryptoJS from "crypto-js"
  export default {
    name:'LoginPage',
    props:['onSave'],
    components: {ShowMsg},
    mounted(){
    },
    data () {
      return {
        email: '',
        passwd: '',
        active: false,
        isError:false
      }
    },
    watch:{},
    computed:{},
    methods:{
      open(){
        this.active = true
      },
      close(){
        this.active = false
      },
      redirectPath(path){
        this.$router.push(path).catch(err => {})
      },
      login(){
        this.isError = false
        if(!this.email||!this.passwd){
          this.isError = true
          return
        }
        var self = this
        var request = {
            method: 'POST',
            url: '/mycalMemberLogin',
            data: this.Qs.stringify({
              email: this.email,
              passwd: CryptoJS.MD5(this.passwd).toString(),
            }),
        }
        this.$http(request).then(function(response) {
          // console.log('message: ' + response.data.data)
          if(response.data.data.message == '登入成功'){
            sessionStorage.setItem('myCalToken', response.data.data.token)
            sessionStorage.setItem('myCalName',response.data.data.memberName)
            sessionStorage.setItem('myCalId',response.data.data.memberId)
            sessionStorage.setItem('myCalEmail',response.data.data.email)
            sessionStorage.setItem('myCalExpiration',response.data.data.expiration)
            self.onSave()
            self.close()
          } else if(response.data.data.message == '很抱歉, 您的帳號或密碼錯誤, 請聯絡管理員~'){
            self.isError = true
          } else if(response.data.data.message == '很抱歉, 您的帳號已停用, 請聯絡管理員~'){
            self.$refs.ShowMsg.open(13,'','')
          }
        }).catch(()=>{
          self.isError = true
        })
      },
    }
  }
</script>